<template>
    <!--begin::Card-->
    <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
            <!--begin::Stepper-->
            <div id="kt_create_account_stepper" ref="horizontalWizardRef" class="stepper stepper-links">
                <!--begin::Nav-->
                <div class="stepper-nav row">
                    <!--begin::Step 1-->
                    <div class="stepper-item current col first" data-kt-stepper-element="nav">
                        <h3 class="stepper-title"><span class="stepper-step">1</span>{{ $t("pages.surveyadd.step1") }}</h3>
                    </div>
                    <!--end::Step 1-->

                    <!--begin::Step 2-->
                    <div class="stepper-item col" data-kt-stepper-element="nav">
                        <h3 class="stepper-title"><span class="stepper-step">2</span>{{ $t("pages.surveyadd.step2") }}</h3>
                    </div>
                    <!--end::Step 2-->
                    <!--begin::Step 3-->
                    <div class="stepper-item col" data-kt-stepper-element="nav">
                        <h3 class="stepper-title"><span class="stepper-step">3</span>{{ $t("pages.surveyadd.step3") }}</h3>
                    </div>
                    <!--end::Step 3-->
                    <!--begin::Step 4-->
                    <div class="stepper-item col last" data-kt-stepper-element="nav">
                        <h3 class="stepper-title"><span class="stepper-step">4</span>{{ $t("pages.surveyadd.step4") }}</h3>
                    </div>
                    <!--end::Step 4 -->
                </div>
                <!--end::Nav-->

                <!--begin::Form-->
                <form id="kt_create_account_form" class="mx-auto w-100 pt-15 pb-10" novalidate="novalidate" @submit="handleStep">
                    <!--begin::Step 1-->
                    <div class="current" data-kt-stepper-element="content">
                        <div class="w-100">
                            <!--begin::Input group-->
                            <div class="fv-row mb-10">
                                <!--begin::Label-->
                                <label class="form-label required">{{ $t("pages.surveyadd.surveylabel") }}</label>
                                <!--end::Label-->

                                <!--begin::Input-->
                                <Field id="label" v-model="this.survey.label" class="form-control form-control-lg" name="label" type="text" />
                                <!--end::Input-->
                            </div>
                            <div class="fv-row mb-10">
                                <!--begin::Label-->
                                <label class="form-label required">{{ $t("pages.surveyadd.surveydescription") }}</label>
                                <Field v-model="this.survey.description" as="textarea" class="form-control form-control-lg" name="description" />
                                <!--end::Input-->
                            </div>
                            <!--end::Input group-->

                            <!--begin::Input group-->
                            <div class="row mb-10">
                                <!--begin::Label-->
                                <div class="col mb-10">
                                    <!--begin::Label-->
                                    <label class="form-label required">{{ $t("pages.surveyadd.date_begin") }}</label>
                                    <input v-model="this.survey.date_begin" class="form-control form-control-lg" type="date" />
                                    <!--end::Input-->
                                </div>
                                <div class="col mb-10">
                                    <!--begin::Label-->
                                    <label class="form-label required">{{ $t("pages.surveyadd.date_end") }}</label>
                                    <input v-model="this.survey.date_end" class="form-control form-control-lg" type="date" />
                                    <!--end::Input-->
                                </div>
                                <!--end::Hint-->
                            </div>
                            <div class="fv-row">
                                <!--begin::Label-->
                                <label class="form-label required">{{ $t("pages.surveyadd.languages") }}</label>
                                <!--end::Label-->
                                <el-select
                                    v-model="this.survey.languages"
                                    allow-create
                                    class="w-100"
                                    default-first-option
                                    filterable
                                    multiple
                                    placeholder="Choose tags for your target"
                                >
                                    <el-option label="Français" value="fr"></el-option>
                                    <el-option label="Anglais" value="anglais"></el-option>
                                </el-select>
                            </div>
                            <!--end::Input group-->
                        </div>
                    </div>
                    <!--end::Step 1-->

                    <!--begin::Step 2-->
                    <div data-kt-stepper-element="content">
                        <div class="d-block w-100">
                            <div class="row">
                                <div class="col-12">
                                    <h3>Créez votre questionnaire</h3>
                                    <p class="pt-4">
                                        Pour créer votre questionnaire, vous pouvez choisir des questions de la questiothèque (banque de questions organisées par thématique et
                                        validées par Obea, notre partenaire externe) ou ajouter une question personnalisée si aucune question de la questiothèque ne correspond à
                                        votre besoin. C’est à vous !
                                    </p>
                                    <button
                                        class="btn btn-primary me-3 w-25"
                                        data-bs-target="#add_custom_question"
                                        data-bs-toggle="modal"
                                        @click="
                                            (e) => {
                                                e.preventDefault();
                                            }
                                        "
                                    >
                                        {{ $t("pages.surveyadd.addcustomquestion") }}
                                    </button>
                                    <button
                                        class="btn btn-primary-inverse w-25"
                                        data-bs-target="#add_system_question"
                                        data-bs-toggle="modal"
                                        @click="
                                            (e) => {
                                                e.preventDefault();
                                            }
                                        "
                                    >
                                        {{ $t("pages.surveyadd.addsystemquestion") }}
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div v-for="question in this.selectedQuestions" v-bind:key="question.question" class="col-12 mt-5">
                                    <div v-if="!question.is_custom" class="card bg-success bg-opacity-25 h-100 mt-3">
                                        <div class="card-body d-flex flex-column pb-8">
                                            <div>
                                                <div class="mb-3">
                                                    <template v-for="thematic in question.thematic" v-bind:key="thematic.name">
                                                        <span v-html="thematic.name"></span>
                                                    </template>
                                                </div>

                                                <div>
                                                    <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                                                    <a class="fs-4 fw-bold text-hover-primary text-gray-900 m-0" href="#">{{ question.question }}</a
                                                    ><br />
                                                </div>
                                                <i>Answers : {{ question.answers }}</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="card bg-info bg-opacity-25 h-100 mt-3">
                                        <div class="card-body d-flex flex-column pb-8">
                                            <div>
                                                <div class="mb-3">
                                                    <template v-for="thematic in question.thematic" v-bind:key="thematic.name">
                                                        <span v-html="thematic.name"></span>
                                                    </template>
                                                </div>

                                                <div>
                                                    <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                                                    <a class="fs-4 fw-bold text-hover-primary text-gray-900 m-0" href="#">{{ question.question }}</a
                                                    ><br />
                                                </div>
                                                <i>Answers : {{ question.answers }}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="add_system_question" class="modal fade" tabindex="-1">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">{{ $t("pages.surveyadd.addsystemquestion") }}</h5>

                                            <!--begin::Close-->
                                            <div aria-label="Close" class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal">
                                                <span class="svg-icon svg-icon-2x"></span>
                                            </div>
                                            <!--end::Close-->
                                        </div>

                                        <div class="modal-body pt-0">
                                            <div class="p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded">
                                                <div class="row">
                                                    <div class="col">
                                                        <select class="form-control form-control-solid">
                                                            <option disabled selected value>{{ $t("pages.surveyadd.thematics") }}</option>
                                                            <option v-for="thematic in this.thematics" v-bind:key="thematic.name">{{ thematic.name }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col">
                                                        <button class="btn btn-primary btn-light" type="submit">
                                                            {{ $t("general.search") }}
                                                        </button>
                                                        <button class="ms-3 btn btn-icon btn-light btn-active-light-primary" type="submit">
                                                            <i class="fas fa-redo fs-7 pt-1"></i>
                                                        </button>
                                                    </div>
                                                    <!--end::Col-->
                                                </div>
                                                <!--end::Row-->
                                            </div>
                                            <div v-for="question in this.ecchoQuestions" v-bind:key="question.question" class="card bg-success bg-opacity-25 h-50 mt-3">
                                                <div class="card-body d-flex flex-column pb-8">
                                                    <div>
                                                        <div class="mb-3">
                                                            <template v-for="thematic in question.thematic" v-bind:key="thematic.name">
                                                                <span class="badge-light badge text-primary me-3">
                                                                    {{ thematic.name }}
                                                                </span>
                                                            </template>
                                                        </div>

                                                        <div>
                                                            <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                                                            <a class="fs-4 fw-bold text-hover-primary text-gray-900 m-0" href="#">{{ question.question }}</a
                                                            ><br />
                                                        </div>
                                                        <!--<i>Answers : {{ question.answers }}</i>-->
                                                    </div>
                                                    <div class="fw-bold mt-3 w-100 text-end">
                                                        <a
                                                            class="btn btn-success btn-sm btn-light btn-active-light-success"
                                                            @click="
                                                                (e) => {
                                                                    e.preventDefault();
                                                                    this.addToSelectedQuestion(question);
                                                                }
                                                            "
                                                            >{{ $t("pages.surveyadd.add") }}</a
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="add_custom_question" class="modal fade" tabindex="-1">
                                <div class="modal-dialog modal-panel-right">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col question-column light-background">
                                                    <h2>{{ $t("pages.surveyadd.addcustomquestion") }}</h2>
                                                    <div class="mb-4">
                                                        <label class="form-label required">{{ $t("pages.surveyadd.thematics") }}</label>
                                                        <el-select
                                                            v-model="this.question.thematics"
                                                            allow-create
                                                            class="w-100"
                                                            default-first-option
                                                            filterable
                                                            multiple
                                                            placeholder=""
                                                        >
                                                            <el-option label="Environnement de travail" value="evt"></el-option>
                                                            <el-option label="Management" value="mgt"></el-option>
                                                        </el-select>
                                                    </div>
                                                    <div class="mb-4">
                                                        <label class="form-label required">{{ $t("pages.surveyadd.question") }}</label>
                                                        <el-input id="question" v-model="this.question.question" name="label" type="text" />
                                                    </div>
                                                    <div class="mb-4">
                                                        <label class="form-label required">{{ $t("pages.surveyadd.type") }}</label>
                                                        <el-select v-model="this.question.type" allow-create class="w-100" default-first-option filterable placeholder="">
                                                            <el-option v-for="type in questionType" :key="type.value" :label="type.value" :value="type.name"></el-option>
                                                        </el-select>
                                                    </div>
                                                    <!--<div class="mb-4">
                                                        <label class="form-label required">{{ $t("pages.surveyadd.answers") }}</label>
                                                        <el-input id="answers" v-model="this.question.answers" name="label" type="text" />
                                                    </div>-->
                                                </div>
                                                <div class="col"></div>
                                                <div class="col"></div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <div aria-label="Close" class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal">
                                                <span class="svg-icon svg-icon-2x"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Step 2-->

                    <!--begin::Step 3-->
                    <div data-kt-stepper-element="content">
                        <div class="card bg-primary bg-opacity-25 h-100 mt-3">
                            <div class="card-body">

                                <div class="m-4">
                                    Télécharger la population cible de l’enquête Pulse :
                                    <button class="btn btn-primary btn-sm">Upload</button>
                                </div>
                                <div class="m-4">Vous pouvez enregistrer l’enquête et télécharger votre fichier avec les collaborateurs à interroger dans un second temps</div>
                            </div>
                        </div>
                    </div>
                    <!--end::Step 3-->
                    <div class="d-flex flex-stack pt-15">
                        <!--begin::Wrapper-->
                        <div class="mr-2">
                            <button class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous" type="button" @click="previousStep">
                                <span class="svg-icon svg-icon-4 me-1">
                                    <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
                                </span>
                                {{ $t("general.back") }}
                            </button>
                        </div>
                        <!--end::Wrapper-->

                        <!--begin::Wrapper-->
                        <div>
                            <button v-if="currentStepIndex === totalSteps - 1" class="btn btn-lg btn-primary me-3" type="button" @click="formSubmit()">
                                {{ $t("general.done") }}
                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                                </span>
                            </button>

                            <button v-else class="btn btn-lg btn-primary" type="submit">
                                {{ $t("general.next") }}
                                <span class="svg-icon svg-icon-4 ms-1 me-0">
                                    <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                                </span>
                            </button>
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Actions-->
                </form>
                <!--end::Form-->
            </div>
            <!--end::Stepper-->
        </div>
        <!--end::Card body-->
    </div>
    <!--end::Card-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { Field } from "vee-validate";

export default defineComponent({
    name: "surveyadd",
    components: { Field },
    methods: {
        addToSelectedQuestion(question: any) {
            if (question) {
                this.selectedQuestions.push(question);
                this.question = {
                    question: "",
                    type: "",
                    thematics: [] as any,
                    answers: [] as any,
                    is_custom: true,
                };
            }
        },
    },
    data() {
        return {
            survey: {
                label: "",
                description: "",
                date_end: "",
                date_begin: "",
                languages: [],
            },
            questionType: [
                {
                    value: "Yes / No",
                    name: "Oui/non",
                },
                {
                    value: "1 to 5",
                    name: "1 à 5 étoiles",
                },
                {
                    value: "4 modalities",
                    name: "4 modalités",
                },
                {
                    value: "Échelle de 1 à 10",
                    name: "Échelle de 1 à 10",
                },
            ],
            question: {
                question: "",
                type: "",
                thematics: [] as any,
                answers: [] as any,
                is_custom: true,
            },
            selectedQuestions: [] as any,
            ecchoQuestions: [] as any,
        };
    },
    created() {
        this.ecchoQuestions.push({
            question: "Mon travail est varié",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
            ],
        });
        this.ecchoQuestions.push({
            question: "Mon poste et mes responsabilités sont clairement définis",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
            ],
        });
        this.ecchoQuestions.push({
            question: "J'ai le sentiment de réaliser un travail qui a du sens",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
                {
                    name: "Engagement",
                },
            ],
        });
    },
    setup() {
        const _stepperObj = ref<StepperComponent | null>(null);
        const horizontalWizardRef = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(0);

        const formData = ref<any>({
            accountType: "personal",
            accountTeamSize: "50+",
            accountName: "",
            accountPlan: "1",
            businessName: "Keenthemes Inc.",
            businessDescriptor: "KEENTHEMES",
            businessType: "1",
            businessDescription: "",
            businessEmail: "corp@support.com",
            nameOnCard: "Max Doe",
            cardNumber: "4111 1111 1111 1111",
            cardExpiryMonth: "1",
            cardExpiryYear: "2",
            cardCvv: "123",
            saveCard: "1",
        });

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(horizontalWizardRef.value as HTMLElement);
        });

        const createAccountSchema = [];

        const currentSchema = computed(() => {
            return createAccountSchema[currentStepIndex.value];
        });

        const { resetForm, handleSubmit } = useForm<any>({
            validationSchema: currentSchema,
        });

        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });

        resetForm({
            values: {
                ...formData.value,
            },
        });

        const handleStep = handleSubmit((values) => {
            console.log(values);

            formData.value = {
                ...formData.value,
                ...values,
            };

            currentStepIndex.value++;

            if (!_stepperObj.value) {
                return;
            }

            _stepperObj.value.goNext();
        });

        const previousStep = () => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value--;

            _stepperObj.value.goPrev();
        };

        const formSubmit = () => {
            Swal.fire({
                text: "Votre enquête a bien été enregistrée.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Valider",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                },
            }).then(() => {
                window.location.reload();
            });
        };
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.surveyadd.title"), [
            {
                link: "/surveys",
                label: t("pages.surveys.title"),
            },
        ]);
        return {
            t,
            horizontalWizardRef,
            previousStep,
            handleStep,
            formSubmit,
            totalSteps,
            currentStepIndex,
        };
    },
    watch: {},
});
</script>
