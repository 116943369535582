
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { Field } from "vee-validate";

export default defineComponent({
    name: "surveyadd",
    components: { Field },
    methods: {
        addToSelectedQuestion(question: any) {
            if (question) {
                this.selectedQuestions.push(question);
                this.question = {
                    question: "",
                    type: "",
                    thematics: [] as any,
                    answers: [] as any,
                    is_custom: true,
                };
            }
        },
    },
    data() {
        return {
            survey: {
                label: "",
                description: "",
                date_end: "",
                date_begin: "",
                languages: [],
            },
            questionType: [
                {
                    value: "Yes / No",
                    name: "Oui/non",
                },
                {
                    value: "1 to 5",
                    name: "1 à 5 étoiles",
                },
                {
                    value: "4 modalities",
                    name: "4 modalités",
                },
                {
                    value: "Échelle de 1 à 10",
                    name: "Échelle de 1 à 10",
                },
            ],
            question: {
                question: "",
                type: "",
                thematics: [] as any,
                answers: [] as any,
                is_custom: true,
            },
            selectedQuestions: [] as any,
            ecchoQuestions: [] as any,
        };
    },
    created() {
        this.ecchoQuestions.push({
            question: "Mon travail est varié",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
            ],
        });
        this.ecchoQuestions.push({
            question: "Mon poste et mes responsabilités sont clairement définis",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
            ],
        });
        this.ecchoQuestions.push({
            question: "J'ai le sentiment de réaliser un travail qui a du sens",
            thematic: [
                {
                    name: "Travail au quotidien",
                },
                {
                    name: "Engagement",
                },
            ],
        });
    },
    setup() {
        const _stepperObj = ref<StepperComponent | null>(null);
        const horizontalWizardRef = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(0);

        const formData = ref<any>({
            accountType: "personal",
            accountTeamSize: "50+",
            accountName: "",
            accountPlan: "1",
            businessName: "Keenthemes Inc.",
            businessDescriptor: "KEENTHEMES",
            businessType: "1",
            businessDescription: "",
            businessEmail: "corp@support.com",
            nameOnCard: "Max Doe",
            cardNumber: "4111 1111 1111 1111",
            cardExpiryMonth: "1",
            cardExpiryYear: "2",
            cardCvv: "123",
            saveCard: "1",
        });

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(horizontalWizardRef.value as HTMLElement);
        });

        const createAccountSchema = [];

        const currentSchema = computed(() => {
            return createAccountSchema[currentStepIndex.value];
        });

        const { resetForm, handleSubmit } = useForm<any>({
            validationSchema: currentSchema,
        });

        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });

        resetForm({
            values: {
                ...formData.value,
            },
        });

        const handleStep = handleSubmit((values) => {
            console.log(values);

            formData.value = {
                ...formData.value,
                ...values,
            };

            currentStepIndex.value++;

            if (!_stepperObj.value) {
                return;
            }

            _stepperObj.value.goNext();
        });

        const previousStep = () => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value--;

            _stepperObj.value.goPrev();
        };

        const formSubmit = () => {
            Swal.fire({
                text: "Votre enquête a bien été enregistrée.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Valider",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                },
            }).then(() => {
                window.location.reload();
            });
        };
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.surveyadd.title"), [
            {
                link: "/surveys",
                label: t("pages.surveys.title"),
            },
        ]);
        return {
            t,
            horizontalWizardRef,
            previousStep,
            handleStep,
            formSubmit,
            totalSteps,
            currentStepIndex,
        };
    },
    watch: {},
});
